<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row>
          <!-- Id  -->
          <b-col cols="12">
            <b-form-group>
              <label for="idInput">Id:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.id" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- User Name  -->
          <b-col cols="3">
            <b-form-group>
              <label for="usernameInput">User Name:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.username" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- payment_method  -->
          <b-col cols="3">
            <b-form-group>
              <label for="payment_methodInput">Payment method:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.payment_method" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- deposit_amount  -->
          <b-col cols="3">
            <b-form-group>
              <label for="deposit_amountInput">Deposit Amount:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.deposit_amount" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- Fee  -->
          <b-col cols="3">
            <b-form-group>
              <label for="feeInput">Fee:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.fee" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- total_payment  -->
          <b-col cols="3">
            <b-form-group>
              <label for="total_paymentInput">total_payment:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.total_payment" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- Note  -->
          <b-col cols="3">
            <b-form-group id="input-note">
              <label for="noteInput">Note:</label>
              <b-form-input
                name="note"
                v-model="editedItem.note"
                type="text"
                aria-describedby="input-note"
                data-vv-as="note"
                :class="{ input: true, 'is-danger': errors.has('note') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('note')" class="is-danger-text position-relative">
                {{ errors.first('note') }}
              </span>
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col cols="6">
            <b-form-group id="input-status" label="Status:" label-for="Status">
              <b-form-select
                v-model="filter_status"
                :options="statusList"
                name="Status"
                data-vv-as="Status"
                :class="{ input: true, 'is-danger': errors.has('Status') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select Status--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select Status --</b-form-select-option>
                </template>
              </b-form-select>
              <span v-show="errors.has('Status')" class="is-danger-text position-relative">
                {{ errors.first('Status') }}
              </span>
            </b-form-group>
          </b-col>

          <!-- withdraw_id  -->
          <b-col cols="3">
            <b-form-group>
              <label for="withdraw_idInput">Withdraw Id:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.withdraw_id" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- ref_bank_id  -->
          <b-col cols="3">
            <b-form-group id="input-id">
              <label for="ref_bank_idInput">Ref Bank Id:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.ref_bank_id" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- display_deposit_id  -->
          <b-col cols="3">
            <b-form-group>
              <label for="display_deposit_idInput">Display Deposit Id:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.display_deposit_id" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- Ref Transaction Id  -->
          <b-col cols="3">
            <b-form-group>
              <label for="ref_transaction_idInput">Ref Transaction Id:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.ref_transaction_id" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- Ref Transaction Status  -->
          <b-col cols="3">
            <b-form-group>
              <label for="ref_transaction_statusInput">Ref Transaction Status:</label>
              <b-form-input
                :disabled="!isCreated"
                v-model="editedItem.ref_transaction_status"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- Meta Data  -->
          <b-col cols="3">
            <b-form-group>
              <label for="metadataInput">Handler:</label>
              <b-form-input :disabled="!isCreated" v-model="editedItem.metadata" type="text"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Modal Deposit Gateway -->
    <b-modal
      v-model="dialogDepositGateway"
      size="md"
      title="Deposit Gateway Manual"
      ok-title="Deposit"
      header-close-content=""
      @ok="sendRequest"
      @cancel="closeRequest"
    >
      <b-container fluid>
        <label for="paymentIdInput">Order ID:</label>
        <b-row class="mt-1 w-100">
          <b-input
            id="paymentIdInput"
            v-model="paymentId"
            name="paymentId"
            min="0"
            data-vv-as="Order Id"
            :class="{ 'w-100': true, input: true, 'is-danger': errors.has('phoneInput') }"
            v-validate="{ required: true }"
          />
          <span v-show="errors.has('paymentId')" class="is-danger-text position-relative">{{
            errors.first('paymentId')
          }}</span>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <h4 class="b-card-title">Deposit Transactions List</h4>

    <b-row>
      <b-col cols="12">
        <b-button id="button-deposit" class="my-3 float-right" variant="success" @click="dialogDepositGateway = true"
          >Deposit Gateway
        </b-button>
      </b-col>
    </b-row>
    <b-row class="table-filter">
      <!-- Filter UserName -->
      <b-col>
        <b-form-group id="input-name-filter" label="UserName" label-for="username-filter">
          <b-form-input
            id="username-filter"
            v-model="filter_username"
            type="text"
            placeholder="Search username ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- FilterPayment -->
      <b-col>
        <b-form-group id="input-screen-filter" label="Payment Method" label-for="payment-filter">
          <b-form-select id="payment-filter" v-model="filter_method_id" :options="allPaymentList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <!-- Filter Active -->
      <b-col>
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center orderTable"
    >
      <template v-slot:cell(fee)="item">{{ formatPrice(item.item.fee) }}</template>
      <template v-slot:cell(total_payment)="item">{{ formatPrice(item.item.total_payment) }}</template>
      <template v-slot:cell(deposit_amount)="item">{{ formatPrice(item.item.deposit_amount) }}</template>
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Success</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 2" variant="danger">Failed</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Pending</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon
          class="text-success"
          :id="'sync' + item.item.id"
          @click="syncBalance(item.item)"
          :disabled="item.item.status !== 1"
          >flaticon-refresh</v-icon
        >
        <b-tooltip :target="'sync_' + item.item.id" triggers="hover">Sync balance</b-tooltip>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
import { formatDateForView } from '@/utils/time.js';
import Swal from 'sweetalert2';
export default {
  name: 'Deposit Transaction List',
  components: {},
  data() {
    return {
      isCreated: true,
      search: '',
      isBusy: false,
      filter_username: '',
      filter_method_id: null,
      filter_status: null,
      statusList: [
        { value: 1, text: 'Success' },
        { value: 0, text: 'Pending' },
        { value: 2, text: 'Rejected' }
      ],
      headers: [
        { text: 'User Name', key: 'username' },
        { text: 'Display_Deposit_Id', key: 'display_deposit_id' },
        { text: 'created_at', key: 'created_at' },
        { text: 'created_by', key: 'created_by' },
        { text: 'Payment_Method', key: 'payment_method' },
        { text: 'Deposit_Amount', key: 'deposit_amount' },
        { text: 'Fee', key: 'fee' },
        { text: 'Note', key: 'note' },
        { text: 'Total_Payment', key: 'total_payment' },
        { text: 'Ref Transaction Id', key: 'ref_transaction_id' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      allPaymentList: [],
      selectedPayment: null,
      dialogDepositGateway: false,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      paymentId: '',
      editedItem: {
        id: '',
        username: '',
        payment_method: '',
        deposit_amount: '',
        fee: '',
        total_payment: '',
        withdraw_id: '',
        display_deposit_id: '',
        status: '',
        note: '',
        ref_bank_id: '',
        ref_transaction_id: '',
        ref_transaction_status: '',
        metadata: ''
      },
      dialog: false,
      editedIndex: -1
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return !this.editedItem.status || this.errors.has('Status');
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Deposit Transaction List' }]);
  },
  methods: {
    cancelRequest() {
      this.dialogDepositGateway = false;
      this.paymentId = '';
    },
    sendRequest() {
      ApiService.post('/payment/retry-gateway-order', { payment_id: this.paymentId })
        .then((resp) => {
          if (resp.data.status) {
            Swal.fire({
              icon: 'success',
              title: 'Payment Updated',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              this.fetchData();
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Error occurs. Try again!',
            text: err,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.cancelRequest();
        });
    },
    retryItem(item) {
      ApiService.post('/payments/retry', { deposit_id: item.display_deposit_id })
        .then((resp) => {
          if (resp.data.status) {
            Swal.fire({
              icon: 'success',
              title: 'Payment Updated',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              this.fetchData();
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Error occurs. Try again!',
            text: err,
            showConfirmButton: false,
            timer: 3000
          });
        });
    },
    syncBalance(item) {
      ApiService.post('/accounts/sync-balance', { account_id: item.account_id })
        .then((resp) => {
          if (resp.status === 204) {
            Swal.fire({
              icon: 'success',
              title: 'Payment Updated',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              this.fetchData();
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Error occurs. Try again!',
            text: err,
            showConfirmButton: false,
            timer: 3000
          });
        });
    },
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsDeposit = ApiService.get(
        'deposit-transactions',
        `?username=${this.filter_username}&status=${this.filter_status == null ? '' : this.filter_status}&method_id=${
          this.filter_method_id || ''
        }&offset=${offset}&limit=${limit}`
      );
      const allPayments = ApiService.get('allpayment');
      axios
        .all([adsDeposit, allPayments])
        .then(
          axios.spread((...response) => {
            const adsDepositRes = response[0];
            const allPaymentRes = response[1];
            this.items = adsDepositRes.data.data.depositTransactionList.map((ads) => {
              return {
                ...ads,
                created_at: formatDateForView(ads.created_at)
              };
            });
            this.rows = adsDepositRes.data.data.total;
            this.allPaymentList = allPaymentRes.data.data;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    editItem: function (item) {
      this.isCreated = false;
      this.dialog = true;
      this.modalTitle = 'Update Article';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      this.selectedPayment = item.payment;
      this.filter_payment_method = item.payment_method;
    },

    close() {
      this.dialog = false;
      this.editedItem = {
        id: '',
        username: '',
        payment_method: '',
        deposit_amount: '',
        fee: '',
        total_payment: '',
        withdraw_id: '',
        display_deposit_id: '',
        status: '',
        note: '',
        ref_bank_id: '',
        ref_transaction_id: '',
        ref_transaction_status: '',
        metadata: ''
      };
      this.editedIndex = -1;
      this.selectedPayment = null;
      this.filter_status = null;
      this.filter_payment_method = '';
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Get new brand/cate/vendor name for data-table
      let productData = {
        note: this.editedItem.note || undefined,
        status: this.filter_status
      };
      if (this.editedIndex > -1) {
        // Update roles
        axios({
          method: 'PUT',
          url: `deposit-transactions/${this.items[this.editedIndex].id}`,
          data: productData
        })
          .then((response) => {
            if (response.status === 204) {
              this.editedItem.status = this.filter_status;
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.close();
              this.$bvToast.toast('Deposit transaction updated successfully', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            } else {
              this.errors.push(response.data.data);
            }
          })
          .catch((errors) => {
            if (errors) {
              this.$bvToast.toast(`${errors}`, {
                title: null,
                variant: 'danger',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          });
      }
    }
  }
};
</script>
